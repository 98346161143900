























































































import "@/common/assets/stylesheets/application.scss";
import { Component, Vue, Prop } from "vue-property-decorator";
import { organisationModule } from "./store/modules/organisation";

@Component
export default class App extends Vue {
  public get organisation() {
    return organisationModule;
  }

  public get currentRouteName() {
    return this.$route.name;
  }

  public async mounted() {
    await organisationModule.initialize(this.getKeyFromPath());
  }

  getKeyFromPath(): string {
    var key = new URL(location.href).pathname.replace("/", "").toLowerCase();

    if (key.indexOf("/") != -1) {
      key = key.substr(0, key.indexOf("/"));
    }
    return key;
  }
}
